import cn from 'classnames';
import type { ReactNode, SyntheticEvent } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';

import type { LightweightCardContentProps } from './components';
import { CardContentItem, CardLogo } from './components';
import styles from './LightweightCardView.module.scss';

interface Props {
  offer: IClientMicrocreditListItem;

  content: LightweightCardContentProps[];
  badge: ReactNode;
  actions: ReactNode;
  pixelDisplay?: ReactNode;

  promo?: ReactNode;

  onCardClick: (e: SyntheticEvent) => void;
}

export const LightweightCardView = ({ offer, pixelDisplay, content, badge, actions, promo, onCardClick }: Props) => {
  const { organization, advertising, isBankPartner } = offer;

  return (
    <div
      className={cn(styles.container, {
        [styles.clickable]: !!onCardClick,
        [styles.cardWithPromo]: !!promo,
      })}
      onClick={onCardClick}
      data-qa="Card"
    >
      {promo}

      <div className={styles.header}>
        <CardLogo
          logo={organization?.logotypes?.android || organization?.logotypes?.square}
          isPartner={isBankPartner || advertising?.isPartner}
        />

        <Typography.Heading className={styles.text} level={6} as="div">
          {organization.name}
        </Typography.Heading>
      </div>

      {badge}

      <div className={styles.content}>
        {content.map((props) => (
          <CardContentItem {...props} key={props.label} />
        ))}

        {pixelDisplay}
      </div>

      {actions}
    </div>
  );
};
