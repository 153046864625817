import cn from 'classnames';
import type { FC, ReactNode } from 'react';
import React, { memo } from 'react';

import { Typography } from '@sravni/react-design-system';

import styles from './CardContentItem.module.scss';

const { Text } = Typography;

export interface LightweightCardContentProps {
  label: string;
  value: ReactNode;

  valueClassName?: boolean;
  labelClassName?: boolean;
}

export const CardContentItem: FC<LightweightCardContentProps> = memo(
  ({ label, value, valueClassName, labelClassName }) => (
    <div className={styles.wrapper}>
      <Text className={cn(styles.label, labelClassName)}>{label}</Text>
      <Text className={cn(styles.value, valueClassName)}>{value}</Text>
    </div>
  ),
);

CardContentItem.displayName = 'CardContentItem';
